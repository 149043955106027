import React from 'react';
import cn from 'classnames';
import { Card, Button } from '~/common/components/ui-elements';
import { FeatureCardItem } from '../../models';
import styles from './index.module.scss';

interface FeatureCardProps extends FeatureCardItem {
  isMobile?: boolean;
}

export const FeatureCardIcon = React.memo<FeatureCardProps>(
  ({
    id,
    featureMediaSource,
    featureTitle,
    featureDescription,
    fontColor,
    link,
    titleFontSize,
    mobileTitleFontSize,
    textAlignment,
    ctaButtonType,
    ctaButtonWidth,
    ctaButtonAlignment,
    isMobile,
  }) => {
    return (
      <div id={id} className={styles.cardContainer}>
        <Card contentClassName={styles.contentContainer}>
          <div className={styles.imageWrapper}>
            <img data-field="featureCardIcon" src={featureMediaSource} />
          </div>
          <div className={styles.featureCopyWrapper}>
            <div>
              <div
                data-field="featureTitle"
                className={styles.featureTitle}
                style={{
                  color: fontColor,
                  textAlign: textAlignment,
                  ...(isMobile
                    ? mobileTitleFontSize !== 0
                      ? {
                          fontSize: mobileTitleFontSize,
                        }
                      : {}
                    : titleFontSize !== 0
                    ? {
                        fontSize: titleFontSize,
                      }
                    : {}),
                }}
                dangerouslySetInnerHTML={{ __html: featureTitle }}
              />

              {featureDescription && (
                <div
                  data-field="featureDescription"
                  className={styles.featureDescription}
                  style={{ color: fontColor, textAlign: textAlignment }}
                  dangerouslySetInnerHTML={{ __html: featureDescription }}
                />
              )}
            </div>
            {!!link && (
              <div
                className={cn({
                  [styles[`ctaAlignment-${ctaButtonAlignment}`]]:
                    !!ctaButtonAlignment,
                })}
              >
                <Button
                  data-field="ctaButton"
                  href={link?.url}
                  target={link.target}
                  className={styles.cta}
                  buttonWidth={ctaButtonWidth}
                  type={ctaButtonType}
                >
                  {link.name}
                </Button>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }
);
