import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import ReactPlayer from 'react-player';
import { usePortals } from 'react-portal-hook';
import { Card, Button } from '~/common/components/ui-elements';
import { FeatureCardItem } from '../../models';
import { VideoPopup } from '../video-popup';
import styles from './index.module.scss';

interface FeatureCardProps extends FeatureCardItem {
  imageHeightOverride?: number;
  isCarousel?: boolean;
  isMobile?: boolean;
  columnSize?: number;
}

export const FeatureCardImageVideo = React.memo<FeatureCardProps>(
  ({
    id,
    cardType,
    featureCardSize,
    featureMediaSource,
    featureMediaType,
    thumbnail,
    videoPlayback,
    featureTitle,
    featureDescription,
    fontColor,
    backgroundColor,
    link,
    titleFontSize,
    mobileTitleFontSize,
    textAlignment,
    useCTAButton,
    ctaButtonWidth,
    ctaButtonType,
    ctaButtonAlignment,
    useImageBackground,
    legalDisclaimer,
    legalDisclaimerFontColor,
    imageHeightOverride,
    isCarousel,
    columnSize,
    isMobile,
  }) => {
    const portalManager = usePortals();
    const videoRef = useRef<ReactPlayer>(null);
    const [isPlayVideo, setPlayVideo] = useState<boolean>(true);

    const handleClick = () => {
      window.open(link?.url, link?.target || '_self');
    };

    const handleEnded = () => {
      setPlayVideo(false);
      if (!!videoRef.current) {
        videoRef.current.seekTo(0);
        videoRef.current.showPreview();
      }
    };

    const handlePlayVideo = () => {
      if (videoPlayback === 'popup') {
        handleEnded();

        portalManager.open((portal) => (
          <VideoPopup
            closeModal={portal.close}
            videoUrl={featureMediaSource}
            thumbnail={thumbnail}
          />
        ));
      } else {
        setPlayVideo(true);
      }
    };

    useEffect(() => {
      if (!!videoRef.current && !!thumbnail) {
        videoRef.current.seekTo(0, 'seconds');
      }

      const t = setTimeout(() => {
        setPlayVideo(false);
      }, 500);

      return () => clearTimeout(t);
    }, []);

    return (
      <div
        id={id}
        className={cn(
          styles.cardContainer,
          styles.roundedTop,
          styles.roundedBottom,
          {
            [styles.cardCarousel]: isCarousel,
            [styles.addSpacing]: backgroundColor === 'transparent',
            [styles[`cardSize${featureCardSize}`]]: true,
          }
        )}
      >
        <Card
          style={{ backgroundColor }}
          onClick={!!link && !useCTAButton ? handleClick : undefined}
          contentClassName={cn(styles.contentContainer, styles.roundedBottom)}
          className={cn(
            styles.roundedTop,
            styles.roundedBottom,
            styles[`size${featureCardSize || 'Auto'}`],
            {
              [styles.hyperlink]: !!link && !useCTAButton,
              [styles.sideWideNonCarousel]:
                featureCardSize === 'Wide' && !isCarousel,
            }
          )}
        >
          <div
            className={cn(
              styles.imageWrapper,
              styles.roundedTop,
              styles[`imageSize${featureCardSize}`],
              {
                [styles.imageCarousel]: isCarousel,
                [styles.imageFull]: useImageBackground,
                [styles[`imageColumn${columnSize}`]]: !useImageBackground,
              }
            )}
            style={{
              ...(!!imageHeightOverride && imageHeightOverride > 0
                ? {
                    height: imageHeightOverride,
                    maxHeight: imageHeightOverride,
                  }
                : {}),
            }}
          >
            {featureMediaType === 'Image' && cardType === 'Image' && (
              <img
                src={featureMediaSource}
                className={styles.roundedTop}
                data-field="featureCardImage"
              />
            )}

            {featureMediaType === 'Video' && cardType === 'Video' && (
              <div className={cn(styles.videoContent, styles.roundedTop)}>
                <ReactPlayer
                  data-field="featureCardVideo"
                  controls
                  width="100%"
                  height="100%"
                  pip={false}
                  ref={videoRef}
                  url={featureMediaSource}
                  onClickPreview={handlePlayVideo}
                  onEnded={handleEnded}
                  playing={isPlayVideo}
                  config={{
                    file: {
                      forceAudio: true,
                      forceVideo: true,
                    },
                  }}
                  light={
                    !!thumbnail ? (
                      <img
                        src={thumbnail}
                        alt="Thumbnail"
                        className={styles.roundedTop}
                      />
                    ) : (
                      false
                    )
                  }
                />
              </div>
            )}

            {legalDisclaimer && (
              <div
                data-field="legalDisclaimer"
                className={styles.legalDisclaimer}
                style={{ color: legalDisclaimerFontColor }}
                dangerouslySetInnerHTML={{ __html: legalDisclaimer }}
              />
            )}
          </div>
          <div
            className={cn(styles.featureCopyWrapper, styles.roundedBottom, {
              [styles.copyCarousel]: isCarousel,
              [styles.imageBackground]: useImageBackground,
              [styles[`copySize${featureCardSize}`]]: true,
            })}
          >
            <div>
              <div
                data-field="featureTitle"
                className={styles.featureTitle}
                style={{
                  color: fontColor,
                  textAlign: textAlignment,
                  ...(isMobile
                    ? mobileTitleFontSize !== 0
                      ? {
                          fontSize: mobileTitleFontSize,
                        }
                      : {}
                    : titleFontSize !== 0
                    ? {
                        fontSize: titleFontSize,
                      }
                    : {}),
                }}
                dangerouslySetInnerHTML={{ __html: featureTitle }}
              />

              {featureDescription && (
                <div
                  data-field="featureDescription"
                  className={styles.featureDescription}
                  style={{ color: fontColor, textAlign: textAlignment }}
                  dangerouslySetInnerHTML={{ __html: featureDescription }}
                />
              )}
            </div>
            {useCTAButton && !!link && (
              <div
                className={cn({
                  [styles[`ctaAlignment-${ctaButtonAlignment}`]]:
                    !!ctaButtonAlignment,
                })}
              >
                <Button
                  data-field="ctaButton"
                  href={link?.url}
                  target={link.target}
                  className={styles.cta}
                  buttonWidth={ctaButtonWidth}
                  type={ctaButtonType}
                >
                  {link.name}
                </Button>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }
);
