import React from 'react';
import cn from 'classnames';
import { Card, Button } from '~/common/components/ui-elements';
import { FeatureCardItem } from '../../models';
import styles from './index.module.scss';

interface FeatureCardProps extends FeatureCardItem {
  imageHeightOverride?: number;
  isCarousel?: boolean;
  isMobile?: boolean;
  columnSize?: number;
  bulletItems?: string[];
}

export const FeatureCardTextOnly = React.memo<FeatureCardProps>(
  ({
    id,
    featureTitle,
    featureDescription,
    fontColor,
    backgroundColor,
    link,
    titleFontSize,
    mobileTitleFontSize,
    textAlignment,
    ctaButtonWidth,
    ctaButtonType,
    ctaButtonAlignment,
    isCarousel,
    isMobile,
    bulletItems,
  }) => {
    return (
      <div
        id={id}
        className={cn(
          styles.cardContainer,
          styles.roundedTop,
          styles.roundedBottom,
          {
            [styles.cardCarousel]: isCarousel,
            [styles.addSpacing]: backgroundColor === 'transparent',
          }
        )}
      >
        <Card
          className={cn(
            styles.cardWrapper,
            styles.roundedTop,
            styles.roundedBottom
          )}
          contentClassName={cn(styles.contentContainer, styles.roundedBottom)}
          style={{ backgroundColor }}
        >
          <div className={cn(styles.featureCopyWrapper, styles.roundedBottom)}>
            <div>
              <div
                data-field="featureTitle"
                className={styles.featureTitle}
                style={{
                  color: fontColor,
                  textAlign: textAlignment,
                  ...(isMobile
                    ? mobileTitleFontSize !== 0
                      ? {
                          fontSize: mobileTitleFontSize,
                        }
                      : {}
                    : titleFontSize !== 0
                    ? {
                        fontSize: titleFontSize,
                      }
                    : {}),
                }}
                dangerouslySetInnerHTML={{ __html: featureTitle }}
              />

              <ul data-field="bulletItems" className={styles.bulletItems}>
                {bulletItems?.map((item, index) => (
                  <li key={index}>
                    <div
                      style={{ color: fontColor }}
                      className={styles.bulletItem}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  </li>
                ))}
              </ul>

              {featureDescription && (
                <div
                  data-field="featureDescription"
                  className={styles.featureDescription}
                  style={{ color: fontColor }}
                  dangerouslySetInnerHTML={{ __html: featureDescription }}
                />
              )}
            </div>

            {!!link && (
              <div
                className={cn({
                  [styles[`ctaAlignment-${ctaButtonAlignment}`]]:
                    !!ctaButtonAlignment,
                })}
              >
                <Button
                  data-field="ctaButton"
                  href={link?.url}
                  target={link.target}
                  className={styles.cta}
                  buttonWidth={ctaButtonWidth}
                  type={ctaButtonType}
                >
                  {link.name}
                </Button>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }
);
