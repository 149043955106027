import React, { CSSProperties, useEffect, useState } from 'react';
import cn from 'classnames';
import { PortalProvider } from 'react-portal-hook';
import { Carousel } from 'react-responsive-carousel';
import { Link } from '~/common/models';
import {
  Button,
  ButtonTypeEnum,
  SvgIcon,
} from '~/common/components/ui-elements';
import { FeatureCardIcon } from './components/feature-card-icon';
import { FeatureCardTextOnly } from './components/feature-card-text-only';
import { FeatureCardImageVideo } from './components/feature-card-image-video';
import { FeatureCardItem } from './models';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import styles from './index.module.scss';

export interface FeatureContentProp {
  contentId: number;
  title: string;
  descriptionText: string;
  textAlignment: 'center' | 'left' | 'right';
  featureItems: FeatureCardItem[];
  fontColor: string;
  backgroundColor: string;
  enableCarousel: boolean;
  carouselOnlyOnMobile: boolean;
  carouselInfiniteLoop: boolean;
  showIndicators: boolean;
  renderedItems: number;
  fillScreenWidth: boolean;
  arrowPosition: 'Side' | 'Bottom';
  showArrows: boolean;
  imageHeightOverride: number;
  mobileColumns: number;
  desktopColumns: number;
  ctaButton?: Link;
  ctaButtonType: keyof typeof ButtonTypeEnum;
  bottomDisclaimer?: string;
}

interface CarouselOption {
  centerMode: boolean;
  percentage: number;
}

if (typeof document === 'undefined') {
  React.useLayoutEffect = React.useEffect;
}

export default ({
  contentId,
  backgroundColor,
  ...props
}: FeatureContentProp) => {
  const { containerRef, isMobile } = useIsMobile();
  const [isMobileScreen, setIsMobileScreen] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<CarouselOption>({
    percentage: 100 / (props.renderedItems === 0 ? 1 : props.renderedItems),
    centerMode: true,
  });

  let gridTemplateStyle;
  if (isMobileScreen) {
    const mobileGrid: CSSProperties = {
      ...styles,
      gridTemplateColumns: `repeat(${
        props.mobileColumns === 0 ? 1 : props.mobileColumns
      }, 1fr)`,
    };
    gridTemplateStyle = mobileGrid;
  } else {
    if (props.featureItems.length === 2) {
      const desktopGrid: CSSProperties = {
        ...styles,
        gridTemplateColumns: `repeat(auto-fit, 24%)`,
        justifyContent: `center`,
      };
      gridTemplateStyle = desktopGrid;
    } else {
      const desktopGrid: CSSProperties = {
        ...styles,
        gridTemplateColumns: `repeat(${
          props.desktopColumns === 0
            ? props.featureItems.length
            : props.desktopColumns
        }, 1fr)`,
      };
      gridTemplateStyle = desktopGrid;
    }
  }

  React.useLayoutEffect(() => {
    if (isMobile()) {
      setIsMobileScreen(true);
      setOptions({
        centerMode: false,
        percentage: 100,
      });
    }
  }, [containerRef.current]);

  return (
    <PortalProvider>
      <div
        ref={containerRef}
        id={`cid-${contentId}`}
        className={styles.featureContent}
        style={{ backgroundColor }}
      >
        <div className="container">
          <div
            data-field="title"
            className={styles.titleWrapper}
            style={{ textAlign: props.textAlignment, color: props.fontColor }}
            dangerouslySetInnerHTML={{ __html: props.title }}
          />

          {props.descriptionText && (
            <div
              data-field="description"
              className={styles.descriptionText}
              style={{ textAlign: props.textAlignment, color: props.fontColor }}
              dangerouslySetInnerHTML={{ __html: props.descriptionText }}
            />
          )}
        </div>
        <div className={cn({ container: !props.fillScreenWidth })}>
          {props.enableCarousel ? (
            props.carouselOnlyOnMobile ? (
              isMobileScreen ? (
                <CarouselContent
                  centerMode={options.centerMode}
                  percentage={options.percentage}
                  backgroundColor={backgroundColor}
                  contentId={contentId}
                  isMobile={isMobileScreen}
                  {...props}
                />
              ) : (
                <div
                  className={styles.gridFeatureContent}
                  style={gridTemplateStyle}
                >
                  {props.featureItems.map((item, index) =>
                    item.cardType === 'Image' || item.cardType === 'Video' ? (
                      <FeatureCardImageVideo
                        {...item}
                        key={index}
                        isMobile={isMobileScreen}
                        isCarousel={props.enableCarousel}
                        columnSize={props.featureItems.length}
                        imageHeightOverride={props.imageHeightOverride}
                      />
                    ) : item.cardType === 'TextOnly' ? (
                      <FeatureCardTextOnly
                        {...item}
                        key={index}
                        isMobile={isMobileScreen}
                      />
                    ) : null
                  )}
                </div>
              )
            ) : (
              <CarouselContent
                centerMode={options.centerMode}
                percentage={options.percentage}
                backgroundColor={backgroundColor}
                contentId={contentId}
                isMobile={isMobileScreen}
                {...props}
              />
            )
          ) : (
            <div
              className={styles.gridFeatureContent}
              style={gridTemplateStyle}
            >
              {props.featureItems.map((item, index) =>
                item.cardType === 'Image' || item.cardType === 'Video' ? (
                  <FeatureCardImageVideo
                    {...item}
                    key={index}
                    isCarousel={props.enableCarousel}
                    imageHeightOverride={props.imageHeightOverride}
                    isMobile={isMobileScreen}
                    columnSize={props.featureItems.length}
                  />
                ) : item.cardType === 'TextOnly' ? (
                  <FeatureCardTextOnly
                    {...item}
                    key={index}
                    isMobile={isMobileScreen}
                  />
                ) : item.cardType === 'Icon' ? (
                  <FeatureCardIcon
                    {...item}
                    key={index}
                    isMobile={isMobileScreen}
                  />
                ) : null
              )}
            </div>
          )}
        </div>
        {props.ctaButton && (
          <div className={cn('container', styles.ctaWrapper)}>
            <Button
              buttonSize="large"
              type={props.ctaButtonType}
              href={props.ctaButton.url}
              target={props.ctaButton.target}
              data-field="ctaButton"
            >
              {props.ctaButton.name}
            </Button>
          </div>
        )}

        {props.bottomDisclaimer && (
          <div className="container">
            <div
              data-field="legalDisclaimer"
              className={styles.bottomDisclaimer}
              dangerouslySetInnerHTML={{ __html: props.bottomDisclaimer }}
            />
          </div>
        )}
      </div>
    </PortalProvider>
  );
};

interface CarouselContentProp extends FeatureContentProp {
  centerMode: boolean;
  percentage: number;
  isMobile: boolean;
}
const CarouselContent = React.memo<CarouselContentProp>((props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    if (
      props.featureItems.some((i) => i.featureCardSize === 'Wide') ||
      props.isMobile
    ) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(props.showArrows ? 1 : 0);
    }
  }, [props.isMobile]);

  return (
    <Carousel
      swipeable
      infiniteLoop
      autoPlay={false}
      showThumbs={false}
      showStatus={false}
      dynamicHeight={false}
      showArrows={props.showArrows}
      selectedItem={selectedIndex}
      onChange={(index) => setSelectedIndex(index)}
      className={cn(styles.featureCardCarousel, {
        [styles[`carousel${props.arrowPosition}`]]: true,
        [styles[`carouselHideFirstLast`]]: !props.carouselInfiniteLoop,
      })}
      centerMode={props.centerMode}
      centerSlidePercentage={props.percentage}
      showIndicators={props.showIndicators}
      renderIndicator={(onClick, isSelected) => (
        <div
          onClick={onClick}
          className={cn(styles.indicator, {
            [styles.indicatorSelected]: isSelected,
          })}
        />
      )}
      renderArrowPrev={(onClick) => {
        return (
          props.showArrows && (
            <CarouselArrow
              onClick={onClick}
              enable={props.carouselInfiniteLoop ? true : selectedIndex > 0}
              position={props.arrowPosition}
              type="left"
            />
          )
        );
      }}
      renderArrowNext={(onClick) => {
        return (
          props.showArrows && (
            <CarouselArrow
              onClick={onClick}
              enable={
                props.carouselInfiniteLoop
                  ? true
                  : selectedIndex !== props.featureItems.length - 1
              }
              position={props.arrowPosition}
              type="right"
            />
          )
        );
      }}
    >
      {props.featureItems.map((item, index) =>
        item.cardType === 'Image' || item.cardType === 'Video' ? (
          <FeatureCardImageVideo
            {...item}
            key={index}
            isCarousel
            isMobile={props.isMobile}
            imageHeightOverride={props.imageHeightOverride}
            columnSize={props.renderedItems}
          />
        ) : item.cardType === 'TextOnly' ? (
          <FeatureCardTextOnly
            {...item}
            key={index}
            isCarousel
            isMobile={props.isMobile}
            imageHeightOverride={props.imageHeightOverride}
            columnSize={props.renderedItems}
          />
        ) : item.cardType === 'Icon' ? (
          <FeatureCardIcon {...item} key={index} isMobile={props.isMobile} />
        ) : (
          <div />
        )
      )}
    </Carousel>
  );
});

interface CarouselArrow {
  position: 'Side' | 'Bottom';
  type: 'left' | 'right';
  onClick: () => void;
  enable: boolean;
}

const CarouselArrow = React.memo<CarouselArrow>(
  ({ type, position, onClick, enable }) => (
    <div
      onClick={enable ? onClick : undefined}
      className={cn(
        styles.arrow,
        styles[`arrow_${position}`],
        styles[`arrow_${position}_${type}`],
        {
          [styles.arrow_disabled]: !enable,
        }
      )}
    >
      {type === 'right' ? (
        <SvgIcon
          size={position === 'Bottom' ? 1.2 : 2}
          type="chevronRight"
          color="#979797"
        />
      ) : (
        <SvgIcon
          size={position === 'Bottom' ? 1.2 : 2}
          type="chevronLeft"
          color="#979797"
        />
      )}
    </div>
  )
);
