import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Modal } from '~/common/components/ui-elements';
import styles from './index.module.scss';

interface VideoPopupProps {
  videoUrl: string;
  thumbnail: string;
  closeModal: () => void;
}

export const VideoPopup = React.memo<VideoPopupProps>(
  ({ closeModal, videoUrl }) => {
    const videoRef = useRef<ReactPlayer>(null);

    const handleEnded = () => {
      if (!!videoRef.current) {
        videoRef.current.seekTo(0);
      }
    };

    // useEffect(() => {
    //   videoRef.current?.
    // }, []);

    return (
      <Modal closeModal={closeModal} modalContentClass={styles.videoPopup}>
        <div className={styles.videoContent}>
          <ReactPlayer
            width="100%"
            height="100%"
            pip={false}
            ref={videoRef}
            url={videoUrl}
            onEnded={handleEnded}
            controls
            playing
            config={{
              file: {
                forceAudio: true,
                forceVideo: true,
              },
            }}
          />
        </div>
      </Modal>
    );
  }
);
